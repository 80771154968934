import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

const RangersTestnet = {
  id: 9527,
  name: "RangersTestnet",
  network: "RangersTestnet",
  nativeCurrency: {
    decimals: 18,
    name: "tRPG",
    symbol: "tRPG",
  },
  rpcUrls: {
    default: "https://robin.rangersprotocol.com/api/jsonrpc",
  },
  blockExplorers: {
    default: {
      name: "RangersTestnet",
      url: "https://robin-rangersscan.rangersprotocol.com",
    },
  },
  testnet: true,
};

// Wagmi client
const { chains, provider } = configureChains(
  [RangersTestnet, mainnet],
  [walletConnectProvider({ projectId: "7b5b913815c695657bf99a76e0febcc2" })]
);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
    <Web3Modal
      projectId="7b5b913815c695657bf99a76e0febcc2"
      ethereumClient={ethereumClient}
    />
  </>
);
