import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { trackGtagEvent } from "./helper";
import GA4 from "react-ga4";
const GA4_API_KEY = "XHjMSbHRQne2TDc6cngNCA";
function App() {
  const { address } = useAccount();
  const gaOptions = Object.assign(
    {
      cookieFlags: "SameSite=Strict; Secure",
    },
    address ? { userId: address } : {}
  );
  useEffect(() => {
    GA4.initialize([
      {
        trackingId: GA4_API_KEY,
        gaOptions,
      },
    ]);
    GA4.set({ anonymizeIp: true });
  }, []);
  const handleClick = () => {
    trackGtagEvent("Buy", {
      event_name: "BuyToken",
      value: "g4",
    });
    gtag("event", "Buy", {
      event_name: "BuyToken",
      value: "gtag",
    });
  };

  return (
    <div className="App">
      <Web3Button />
      <button onClick={handleClick}>connect button</button>
      {address ?? "no account"}
    </div>
  );
}

export default App;
